.panel-listing-header {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.5em 2em 0.5em;
  background-color: rgba(24, 115, 79);
}

.panel-listing-header > img.logo {
  width: 100%;
  height: 60%;
  object-fit: contain;
  position: relative;
  z-index: 2;
}

.panel-listing-header > img.bg {
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.panel-listing-header > input {
  width: 100%;
  border: none;
  padding: 0.5em 1em;
  border-radius: 50px;
  position: relative;
  z-index: 2;
}

.panel-listing-header > input::placeholder {
  font-style: italic;
}

.panel-listing-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 0.5em 0.5em 10em 0.5em;
  border-radius: 10px 10px 0px 0px;
  background-color: white;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 10px #00000075;
}

.panel-listing {
  width: 100%;
  position: relative;
  height: 5em;
  border: 2px solid #68c299;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0;
  color: white;
  text-align: left;
  font-size: 0.9em;
  background-color: rgb(15, 117, 70);
}

.panel-listing > div {
  background-image: linear-gradient(to right, #0f7546 61%, transparent 90%);
  height: 100%;
  position: relative;
  z-index: 2;
  width: 100%;
  padding-right: 25%;
  padding-left: 4%;
  display: flex;
  align-items: center;
}

.panel-listing > img {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 50%;
  object-fit: cover;
}

.panel-listing-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  place-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.panel-listing-modal.shown {
  pointer-events: all;
  opacity: 1;
}

.panel-listing-modal.shown > div {
  transform: scale(1);
}

.panel-listing-modal > div {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  position: relative;
  padding: 0.5em 1em;
  transform: scale(0.9);
  transition: all 0.2s ease-in-out;
}

.panel-listing-modal > div > .close-btn {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  place-items: center;
  height: 2em;
  width: 2em;
  border: none;
  background-color: #6ead29;
  color: white;
  font-weight: bold;
  padding-bottom: 0.2em;
}

.panel-listing-modal > div > a {
  display: flex;
  place-items: center;
  height: 4em;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.panel-listing-modal > div > a:first-of-type {
  border: 1px solid #0e3e57;
}

.panel-listing-modal > div > a:first-of-type > div {
  background-image: linear-gradient(to right, #0e3e57 35%, transparent);
}

.panel-listing-modal > div > a:nth-of-type(2) {
  border: 1px solid #376a10;
}

.panel-listing-modal > div > a:nth-of-type(2) > div {
  background-image: linear-gradient(to right, #376a10 35%, transparent);
}

.panel-listing-modal > div > a > img {
  height: 100%;
  position: absolute;
  right: 0;
}

.panel-listing-modal > div > a > div {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 4%;
  width: 100%;
}

.panel-listing-modal > div > .center {
  margin-top: 0;
  font-style: italic;
}
