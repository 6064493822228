#root {
  height: 100%;
  width: 100%;
}

p {
  width: 100%;
  text-align: left;
}

.center {
  text-align: center;
}

a {
  text-decoration: none;
}
