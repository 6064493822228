#home-container {
  width: 100%;
  height: 100vh;
  background-position: bottom left;
}

.home-section {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  flex-shrink: 0;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 5% 5% 6em 5%;
  background-image: url("../../assets/imgs/background_home.jpg");
  background-repeat: no-repeat;
  background-color: #030102;
  background-size: 300% auto;
  background-position-y: top;
  position: relative;
}

.home-section:nth-child(1) {
  background-position-x: 0;
}

.home-section:nth-child(2) {
  background-position-x: 50%;
}

.home-section:nth-child(3) {
  background-position-x: 100%;
}

.home-section:nth-child(1) > img,
.home-section:nth-child(3) > img {
  width: 90%;
}

.panel-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.scroll-step-container {
  position: fixed;
  bottom: 1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5em;
  scroll-behavior: smooth;
}

.scroll-step {
  width: 1em;
  height: 1em;
  border: 2px solid white;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.scroll-step.active {
  background-color: white;
}

.platform-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-size: 1em;
  color: white;
  background-color: transparent;
  border: none;
}

.platform-button:nth-child(2) {
  margin-top: 2em;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.platform-button > div {
  background-color: #5bc553;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  padding: 0.7em;
}

.platform-button > div > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

a {
  color: white;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.5em;
  margin-top: 2em;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 1em;
  width: 1em;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #93eb1a;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.3em;
  top: 0;
  width: 0.2em;
  height: 0.6em;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
