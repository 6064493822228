nav.panel-display {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4em;
  background-color: #32a395;
  border: none;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  display: flex;
  z-index: 2;
}

nav.panel-display > a {
  flex: 1 1 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  color: white;
  gap: 0.2em;
  text-align: center;
  justify-content: center;
  place-items: center;
  transition: all 0.2s ease-in-out;
  font-size: 0.9em;
}

nav.panel-display > a.active {
  background-color: #5bc553;
}

nav.panel-display > a > img {
  width: 100%;
  height: 30%;
  object-fit: contain;
}
